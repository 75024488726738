const results = {
 'funcodec_en_libritts_16k_nq32ds320': {'bps': 16, 'speech': 0.7046634097553245, 'audio': 0.5813143405751103, 'music': 0.6492228250203304, 'WER': 3.28, 'EER': 1.76, 'minDCF': 0.12, 'ACC': 67.63, 'mAP': 25.52},
 'funcodec_en_libritts_16k_nq32ds640': {'bps': 8, 'speech': 0.6781538823670786, 'audio': 0.5782206205739593, 'music': 0.6317069027506513, 'WER': 3.43, 'EER': 2.04, 'minDCF': 0.13, 'ACC': 68.26, 'mAP': 21.43},
 'dac_24k': {'bps': 24, 'speech': 0.8635868731944552, 'audio': 0.635926321401109, 'music': 0.8152730341160777, 'WER': 2.96, 'EER': 2.24, 'minDCF': 0.14, 'ACC': 69.56, 'mAP': 41.37},
 'funcodec_en_libritts_16k_gr1nq32ds320': {'bps': 16, 'speech': 0.723966896525529, 'audio': 0.5824965895396587, 'music': 0.6674006973812399, 'WER': 3.21, 'EER': 1.50, 'minDCF': 0.10, 'ACC': 63.54, 'mAP': 37.31},
 'academicodec_hifi_24k_320d': {'bps': 3, 'speech': 0.6112398890675668, 'audio': 0.5915525047563863, 'music': 0.6035309322126859, 'WER': 4.49, 'EER': 6.16, 'minDCF': 0.36, 'ACC': 65.95, 'mAP': 14.01},
 'encodec_24k_12bps': {'bps': 12, 'speech': 0.7484265360359972, 'audio': 0.6058457904772402, 'music': 0.71023026879839, 'WER': 3.16, 'EER': 1.81, 'minDCF': 0.10, 'ACC': 66.18, 'mAP': 37.77},
 'encodec_24k_24bps': {'bps': 24, 'speech': 0.7750640337850226, 'audio': 0.6089645303954081, 'music': 0.7319528492080156, 'WER': 3.22, 'EER': 3.44, 'minDCF': 0.21, 'ACC': 67.63, 'mAP': 35.84},
 'dac_44k': {'bps': 8, 'speech': 0.8020500330342413, 'audio': 0.7024724561468191, 'music': 0.7695071734476676, 'WER': 9.21, 'EER': 13.88, 'minDCF': 0.68, 'ACC': 58.84, 'mAP': 18.84},
 'funcodec_zh_en_16k_nq32ds320': {'bps': 16, 'speech': 0.7255823069418212, 'audio': 0.5827396676814205, 'music': 0.6653222147388361, 'WER': 3.21, 'EER': 1.52, 'minDCF': 0.11, 'ACC': 69.25, 'mAP': 26.42},
 'funcodec_zh_en_16k_nq32ds640': {'bps': 8, 'speech': 0.7176012643274856, 'audio': 0.582532675090953, 'music': 0.6672976810583563, 'WER': 3.27, 'EER': 1.60, 'minDCF': 0.11, 'ACC': 69.55, 'mAP': 33.59},
 'academicodec_hifi_16k_320d_large_uni': {'bps': 2, 'speech': 0.6166611505694835, 'audio': 0.5743126244264974, 'music': 0.6299426060557733, 'WER': 4.94, 'EER': 4.43, 'minDCF': 0.29, 'ACC': 65.96, 'mAP': 16.19},
 'encodec_24k_3bps': {'bps': 3, 'speech': 0.6359634179396756, 'audio': 0.5988240641791379, 'music': 0.6209707173984711, 'WER': 3.49, 'EER': 4.28, 'minDCF': 0.27, 'ACC': 66.18, 'mAP': 32.43},
 'academicodec_hifi_16k_320d': {'bps': 2, 'speech': 0.6095326866119063, 'audio': 0.5736083819953895, 'music': 0.6010745702722795, 'WER': 4.02, 'EER': 3.31, 'minDCF': 0.24, 'ACC': 65.49, 'mAP': 15.11},
 'audiodec_24k_320d': {'bps': 6.4, 'speech': 0.5955507885063988, 'audio': 0.6020517646070895, 'music': 0.5718777914767119, 'WER': 3.18, 'EER': 3.59, 'minDCF': 0.26, 'ACC': 69.18, 'mAP': 32.04},
 'dac_16k': {'bps': 6, 'speech': 0.7976470668795377, 'audio': 0.5905287787904119, 'music': 0.7494117454378744, 'WER': 3.26, 'EER': 1.59, 'minDCF': 0.12, 'ACC': 68.81, 'mAP': 41.08},
 'encodec_24k_1_5bps': {'bps': 1.5, 'speech': 0.5793470594055835, 'audio': 0.5943991274688806, 'music': 0.5680054954838615, 'WER': 3.17, 'EER': 3.15, 'minDCF': 0.19, 'ACC': 68.26, 'mAP': 36.64},
 'encodec_24k_6bps': {'bps': 6, 'speech': 0.6965480886508195, 'audio': 0.6022051076781016, 'music': 0.6694668229923247, 'WER': 3.28, 'EER': 1.60, 'minDCF': 0.11, 'ACC': 69.55, 'mAP': 33.59},
 'speech_tokenizer_16k': {'bps': 4, 'speech': 0.6438394082130589, 'audio': 0.5814713410971902, 'music': 0.5854609902617357, 'WER': 3.94, 'EER': 5.22, 'minDCF': 0.30, 'ACC': 65.70, 'mAP': 17.41},
 'funcodec_en_libritts_16k_gr8nq32ds320': {'bps': 16, 'speech': 0.7040695674088161, 'audio': 0.5829378807299235, 'music': 0.6678934346550227, 'WER': 3.21, 'EER': 1.52, 'minDCF': 0.11, 'ACC': 69.25, 'mAP': 26.42}
};
export default results;